/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


*{
    font-family:"gotham-medium";
    color:#58595B;
    --ripple-color: transparent;
    outline: none;
    font-size: 14px;
}

.maintaince{
    text-align: center;
}

ion-header {
    box-shadow: 0px 3px 20px rgb(0 0 0 / 9%);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.d-flex{display: flex;align-items: center;}
.ml-auto{margin-left: auto !important;}
.mr-auto{margin-right: auto !important;}
.m-0{margin: 0;}
.p-0{padding: 0;}
.text-center{text-align: center;}
.p-r-l{padding: 0 15px;}

h1,h2,h3,h4,h5,h6{
    font-family: "rexlia";
    font-weight: revert;
}

ion-item{    
    --background-activated: transparent;
}

.header-md::after{
    display: none;
}

ion-footer::after,ion-footer::before{ 
    display: none;
}

ion-footer {
    box-shadow: 0 3px 20px rgb(0 0 0 / 9%);
}

ion-header ion-button {
    text-transform: capitalize;
    color: #000;
}

ion-item:hover {
    --color: #fff;
}

.justify-center{
    justify-content: center;
}

ion-accordion-group ion-accordion ion-item ion-icon.ion-accordion-toggle-icon {
    display: block !important;
    font-size: 40px;
    margin: 0;
    color: #f2f2f2;
    position: relative;
    &:after{
        content: '';
        position: absolute;
        background: url(assets/image/arrow_icon.svg) no-repeat;width: 35px;
        height: 26px;
        z-index: 10;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto !important;
    }
}

// .modal-wrapper:after {content: '';background: #d8d8d8;height: 7px;width: 30px;border-radius: 50px;position: absolute;left: 0;top: 10px;right: 0;margin: auto;}

ion-buttons ion-button {
    height: auto !important;
    --padding-top: 2px !important;
    --padding-bottom: 2px !important;
}

ion-header {
    background: #fff;
    ion-title {
        font-weight: normal;        
        padding:20px 40px;
        font-family: "rexlia";
        color: #000;
        font-size: 40px;
        padding-left: 150px;
        padding-right: 150px;
    }
}

ion-header ion-toolbar {
    --min-height: 184px;
}

ion-header ion-title img {
    width: 260px;
}

ion-header ion-title h3 {
    margin: 20px 0 0;
    display: flex;
    align-items: flex-end;
    color: #fff;
}

ion-header ion-title h3 span {
    margin-left: auto;
}

ion-header ion-title h3 span img {
    width: 100px;
}

ion-footer ion-toolbar button {
    width: 100%;
    height: 46px;
    background: #000;
    border-radius: 50px;
    font-family: "rexlia";
    font-size: 15px;
    color: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
}

ion-button{
    --background-activated:none;
}

.white-icon {
    background: url('./assets/image/back-white.svg');
    background-repeat: no-repeat;
    width: 76px !important;
    height: 76px !important;
    background-size: cover;
    margin: 0 10px;
}

.cross {
    background: url('./assets/image/cross.svg');
    background-repeat: no-repeat;
    width: 76px;
    min-height: 76px !important;
    background-size: contain;
    margin: 0 10px;
}

// ::-webkit-scrollbar {
//     display: none;
// }

.accordion-button::after{
    display: none;
}

.accordion-item {
    border-radius: 0;
    border: none;
}

.accordion {
    margin: 30px 0;
}

button.accordion-button {
    background: transparent !important;
    border: none !important;
    box-shadow: 0 3px 22px rgb(0 0 0 / 4%) !important;
    font-size: 30px;
    color: #000 !important;
}

ion-icon.ion-accordion-toggle-icon {
    display: none;
}

button.accordion-button span {
    font-size: 22px;
    font-family: "rexlia";
    color: #B9034E;
    display: flex;
    align-items: center;
}

button.accordion-button span b {
    background:#F2F2F2;    
    width: 115px;
    height: 51px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    font-size: 20px;
    color: #1f1f1f;
    margin-left: 16px;
    font-weight: normal;
    
}

.accordion-collapsed button.accordion-button span b{
    background: #1f1f1f;
    color: #fff;
}

@media screen and (max-width: 1920px) {

    ion-content {
        --padding-start: 15%;
        --padding-end: 15%;
    }

 }

 @media screen and (max-width: 1080px) {
 
     ion-content {
         --padding-start: 40px;
         --padding-end: 40px;
     }
     ::-webkit-scrollbar {
        width: 0;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
    }
 
  }

  @media screen and (max-width: 1080px) {
  
      ion-content {
          --padding-start: 40px;
          --padding-end: 40px;
      }
  
   }




   /****************************/


.my-custom-class .alert-wrapper.ion-overlay-wrapper {
    min-width: 825px;
    border-radius: 56px;
    text-align: center;
    padding: 450px 30px 74px;
}

.my-custom-class .alert-wrapper.ion-overlay-wrapper .alert-head {
    text-align: center;
    padding: 0;
}

.my-custom-class .alert-wrapper.ion-overlay-wrapper .alert-head h2 {
    font-size: 55px;
    color: #E99AA3;
    font-weight: 700;
    font-family: "gotham-medium";
}

.my-custom-class .alert-wrapper.ion-overlay-wrapper .alert-message {
    font-size: 40px;
    color: #363033;
    font-family: "gotham-medium";
    padding: 20px 0 0;
}

.my-custom-class .alert-wrapper.ion-overlay-wrapper .alert-message{
    max-height: initial !important;
}

.my-custom-class .alert-wrapper.ion-overlay-wrapper .alert-message span.green{
    color: #027A42;
}

.my-custom-class .alert-wrapper.ion-overlay-wrapper .alert-message span.red{
    color: #EE2E2E;
}

.my-custom-class .alert-wrapper.ion-overlay-wrapper .alert-message span{
    font-size: 55px;
    padding: 15px 0 0;
    font-family: "gotham-medium";
}

.my-custom-class .alert-wrapper.ion-overlay-wrapper .alert-button-group {
    text-align: center;
    padding: 0;
}

.my-custom-class .alert-wrapper.ion-overlay-wrapper .alert-button-group button {
    margin: 93px auto 0;
    background: #000;
    min-width: 337px; 
    border-radius: 16px;
    padding: 0 35px;
    height: 88px;
}

.my-custom-class .alert-wrapper.ion-overlay-wrapper .alert-button-group button span {
    font-size: 33px;
    justify-content: center;
    color: #fff;
    text-transform: capitalize;
}

.accordion-button {
    padding: 2rem 3.25rem !important;
}

.alert-wrapper {
    position: absolute;
    z-index: 10004;
    .alert-head {
        &::before {
            content: '';
            background: url(assets/image/bell.svg) no-repeat;
            background-size: contain;
            height: 252px;
            width: 317px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 144px;
            z-index: 9;
            padding: 15px;
        }
    }
}
button.alert-button.cross {
    width: auto !important;
    margin: 0 !important;
    position: absolute;
    padding: 0 !important;
    top: 56px;
    right: 60px;
    width: 48px !important;
    height: 48px !important;
    background: transparent !important;
    min-width: auto !important;
}

button.alert-button.cross span {
    background-image: url(./assets/image/cross.svg) !important;
    background-size: contain;
    background-repeat: no-repeat;
}

.my-custom-class .alert-wrapper.ion-overlay-wrapper .alert-message p strong {
    width: 100%;
    display: block;
    margin: 0 0 15px;
    font-size: 40px;
    color: #ee2e2e;
}

.maintaince-mode-btn{margin: 0;
    position: fixed;
    right: 0px;
    z-index: 99999999;
    top: 0px;
    opacity: 0;
    background-color: red;
    --background:red;
}

ion-button.button-disabled {
    --background: #c8c8c8 !important;
    opacity: 1;
}

.ella-loading .loading-wrapper {
    padding: 0;
    background: transparent;
    box-shadow: none;
}

.ella-loading .loading-wrapper .loading-spinner {
    display: none;
}

.ella-loading ion-backdrop {
    opacity: 1 !important;
    background: white;
}

.timer-img{
    img{
        width: 200px;
    } 
}

ion-modal.cancel-payment {
    --width: 825px;
    --height: 718px;
    --background: transparent;
    text-align: center;
    --box-shadow: none;
}

.my-custom-class.default-modal .alert-wrapper.ion-overlay-wrapper {
    padding-top: 74px;
}

.my-custom-class.default-modal .alert-wrapper.ion-overlay-wrapper .alert-head::before {
    display: none;
}

.custom-head{
    padding-top: 80px;
    span {
        display: block;
        margin: 0 auto 25px;
        border-radius: 25px;
        overflow: hidden;
        width: 350px;
        height: auto;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    h2{
        font-size: 55px;
        color: #E99AA3;
        font-weight: 700;
        font-family: "gotham-medium";
    }
    p{
        font-size: 40px;
        color: #363033;
        font-family: "gotham-medium";
        padding: 20px 0 0;
    }
}

.loader-class ion-backdrop {
    opacity: 1 !important;
    background: rgba($color: #000000, $alpha: 0.8);
}

.loader-class .loading-wrapper {
    padding: 82px;
    max-height: 829px;
   max-width: 825px;
    border-radius: 56px;
}

.loader-class .loading-wrapper .conatiner{
       
        display: flex;
      
        flex-direction: column;
        align-items: center;
}

.loader-class .loading-wrapper .conatiner .text {
    color: #5CB37B;
    font-size: 49px;
    font-weight: bold;
    font-family: 'gotham-medium';
}

.payment_failed .alert-wrapper.ion-overlay-wrapper .alert-head h2 {
    font-size: 55px;
    color: #000000;
    font-weight: 700;
    font-family: "gotham-medium";
}

.payment_failed ion-backdrop {
    opacity: 1 !important;
    background: rgba($color: #000000, $alpha: 0.8);
}

ion-alert.terminalCheck  {
    margin-top: 35px;
}


.terminal-overlay {
    position: absolute;
    height: 35px;
    width: 100%;
    background: rgba(0,0,0,0.32);
    z-index: 1;
    top: 0;
    left: 0;
}

.labelDesc{
    font-size: 20px;
    padding-left: 4.5%;
}

ion-header ion-title .homeBanner img{
    width: 100%;
}
.handClickButton {
    position: relative;
    .handClickIcon {
        position: absolute;
        top: 0;
        left: 50%;
        max-width: 200px;
        transform: translateX(-100px);
    }
  }
  .customLoaderOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    z-index: 111;
    img {
        max-width: 150px;
    }
}
ion-modal.free-coffee-modal, ion-modal.ella-maxdrink-modal, ion-modal.payment-error-modal  {
    --background: transparent !important;
    --backdrop-opacity: 0.8 !important;
    top: 0 !important;
    bottom: 0 !important;
    // height: calc(100% - 266px);
    --width: 100%;
    --height: 100%;
}